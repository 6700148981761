@tailwind base;
@tailwind components;
@tailwind utilities;
/*
* {    
  margin: 0 !important;
  padding: 0 !important;
}
*/

.modal {
  display: none;
}

.modal.active {
  display: flex;
}


